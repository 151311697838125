import React, { useState, useContext } from 'react'

import { languageOptions } from '../languages'
import { LanguageContext } from '../containers/Language'
import downArrow from '../assets/down-arrow.svg'

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext)
  const [showOptions, setShowOptions] = useState(false)

  // set selected language by calling context method
  const handleLanguageChange = (e) => {
    userLanguageChange(e.target.dataset.id)
    setShowOptions(false)
  }

  return (
    <div tabIndex={0} onBlur={() => setShowOptions(false)}>
      <div
        onClick={() => setShowOptions((showOptions) => !showOptions)}
        className="flex items-center justify-center cursor-pointer select-none"
      >
        <span className="w-24">{languageOptions[userLanguage]}</span>
        <img src={downArrow} alt="v" className="w-4 h-4 mt-1 ml-2 text-white" />
      </div>
      <div className="absolute flex items-center w-32 h-24 py-2 lg:h-16">
        {showOptions === true &&
          Object.entries(languageOptions).map(
            ([id, name]) =>
              id !== userLanguage && (
                <div
                  key={id}
                  data-id={id}
                  className="flex items-center w-full h-full bg-transparent cursor-pointer select-none hover:text-slate-300"
                  onClick={handleLanguageChange}
                >
                  {name}
                </div>
              )
          )}
      </div>
    </div>
  )
}
