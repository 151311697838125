import React, { useState } from 'react'

import SuccessBox from '../../components/SuccessBox'
import { Text } from '../../containers/Language'
import { config } from '../../resources/Constants'

export default function Contact() {
  const [showPopUp, setShowPopUp] = useState(false)
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  const [showInvalidEmailError, setShowInvalidEmailError] = useState(false)
  const [showInvalidContentError, setShowInvalidContentError] = useState(false)

  let handleContactButtonClick = () => {
    let everythingIsValid = true

    // Check email is valid
    let validEmailRegularExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!validEmailRegularExp.test(email)) {
      setShowInvalidEmailError(true)
      everythingIsValid = false
    } else {
      setShowInvalidEmailError(false)
    }

    // Check content is not empty
    if (content.length < 10) {
      setShowInvalidContentError(true)
      everythingIsValid = false
    } else {
      setShowInvalidContentError(false)
    }

    if (everythingIsValid) {
      setShowPopUp(true)

      const contact = {
        email: email,
        content: content,
      }

      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contact),
      }

      fetch(config.url.API_URL + '/api/contact/', requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log('error', error))
    }
  }

  return (
    <div id="ContactWrapper" className="min-h-screen rainbowBackground">
      <div className="p-4">
        <h1 className="title">
          {' '}
          <Text tid="contact_title" />
          🤝
        </h1>

        <form
          id="ContactForm"
          className="container p-4 my-12 text-black bg-white rounded-2xl"
        >
          <div className="py-4 email">
            <label className="block m-4 text-4xl">
              <Text tid="contact_email" /> 📧
            </label>

            <div className="w-11/12 rounded-xl border border-solid border-gray-500 m-4 p-[2px] focus-within:border-0 focus-within:bg-gradient-to-r from-[#6889ff] to-[#c668ff]">
              <input
                type="text"
                value={email}
                className="w-full h-full p-4 text-3xl rounded-lg outline-none"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {showInvalidEmailError && (
              <p className="block p-2 px-3 m-4 text-2xl tracking-wider text-white bg-purple-400 w-fit rounded-xl">
                <Text tid="contact_email_error" /> 😶‍🌫️
              </p>
            )}
          </div>
          <div className="py-4 project">
            <label className="block m-4 text-4xl">
              <Text tid="contact_content" /> 🤠
            </label>

            <div className="w-11/12 rounded-xl border border-solid border-gray-500 m-4 p-[2px] focus-within:border-0 focus-within:bg-gradient-to-r from-[#6889ff] to-[#c668ff]">
              <textarea
                rows="5"
                value={content}
                className="w-full h-full p-4 text-3xl rounded-lg outline-none"
                onChange={(e) => setContent(e.target.value)}
              />
            </div>

            {showInvalidContentError && (
              <p className="block p-2 px-3 m-4 text-2xl tracking-wider text-white bg-purple-400 w-fit rounded-xl">
                <Text tid="contact_content_error" /> 👍
              </p>
            )}
          </div>
          <button
            onClick={handleContactButtonClick}
            type="button"
            className="mb-8 text-4xl rainbowButton"
          >
            <Text tid="contact_letsgo" /> ✅
          </button>
        </form>
      </div>
      {showPopUp && (
        <SuccessBox
          setShowPopUp={setShowPopUp}
          contentText={<Text tid="contact_confirm_text" />}
          buttonText={<Text tid="contact_confirm_button" />}
          redirect="/"
        />
      )}
    </div>
  )
}
