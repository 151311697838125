import { NavLink } from 'react-router-dom'

// import instagramIcon from '../assets/networks/instagram.svg'
import linkedinIcon from '../assets/networks/linkedin.svg'
// import twitterIcon from '../assets/networks/twitter.svg'
// import youtubeIcon from '../assets/networks/youtube.svg'
import { Text } from '../containers/Language'

export default function Footer() {
  const siteLinks = [
    {
      tid: 'footer_home',
      url: '/',
    },
    {
      tid: 'footer_contact',
      url: '/contact',
    },
    {
      tid: 'footer_legal',
      url: '/legal',
    },
    {
      tid: 'footer_blog',
      url: '/blog',
    },
  ]

  // TODO : Mettre les bon liens vers les réseaux sociaux
  const socialNetworksLinks = [
    // {
    //   name: 'Instagram',
    //   image: instagramIcon,
    //   url: 'https://www.instagram.com',
    // },
    {
      name: 'LinkedIn',
      image: linkedinIcon,
      url: 'https://www.linkedin.com/in/charles-godaert/',
    },
    // ,
    // {
    //   name: 'Twitter',
    //   image: twitterIcon,
    //   url: 'https://www.twitter.com',
    // },
    // {
    //   name: 'YouTube',
    //   image: youtubeIcon,
    //   url: 'https://www.youtube.com',
    // },
  ]

  return (
    <div
      id="FooterWrapper"
      className="flex flex-col items-center justify-center w-full px-8 py-2 text-2xl text-black xl:flex-row h-42 gap-y-4 gap-x-32 bg-slate-100 opacity-80"
    >
      <div
        id="FooterLinks"
        className="flex flex-row justify-center py-2 font-semibold xl:px-0 gap-x-12"
      >
        {siteLinks.map(({ tid, url }) => (
          <NavLink
            key={tid}
            to={url}
            className="whitespace-nowrap hover:text-pink-600"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }}
          >
            <Text tid={tid} />
          </NavLink>
        ))}
      </div>

      <div className="flex flex-row items-center justify-center h-12 gap-x-8">
        {socialNetworksLinks.map(({ name, image, url }) => (
          <a target="_blank" rel="noopener noreferrer" href={url} key={name}>
            <img alt={name} src={image} className="h-12 w-12 min-w-[2rem]" />
          </a>
        ))}
      </div>
      <p className="py-2 text-xl tracking-wider text-center text-slate-800 lg:text-left">
        <Text tid="footer_copyright" />
      </p>
    </div>
  )
}
