import React, { useState, useEffect, useContext, useRef } from 'react'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
import { HelmetProvider, Helmet } from 'react-helmet-async'

import ArticleInBlog from '../../components/ArticleInBlog'
import { Text } from '../../containers/Language'
import { LanguageContext } from '../../containers/Language'
import { config } from '../../resources/Constants'
import Notify from '../../class/notify'

export default function Blog() {
  const [articlesList, setArticlesList] = useState([])
  const [categoriesList, setCategoriesList] = useState([])

  const newsletterEmailAddressInputRef = useRef(null)

  const { userLanguage } = useContext(LanguageContext)

  const saveNewsletterEmailAddress = (e) => {
    e.preventDefault()

    let emailAddress = newsletterEmailAddressInputRef.current.value

    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: emailAddress,
      }),
    }

    fetch(config.url.API_URL + '/api/newsletter', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            'HTTP status : ' + response.status + '\n' + response.statusText
          )
        }
        return response.text()
      })
      .then((result) => {
        let res = JSON.parse(result)
        console.log(res)
        if (res.message === 'UserAlreadySubscribed') {
          Notify.info(<Text tid="blog_inform_confirm_already_registered" />)
        } else if (res.message === 'InvalidEmailFormat') {
          Notify.error(<Text tid="contact_email_error" />)
        } else {
          Notify.success(<Text tid="blog_inform_confirm_success" />)
          newsletterEmailAddressInputRef.current.value = ''
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  // Get all articles
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
    }

    fetch(config.url.API_URL + '/api/blog/', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setArticlesList(JSON.parse(result))
      })
      .catch((error) => console.log('error', error))
  }, [])

  // Get all categories
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
    }

    fetch(config.url.API_URL + '/api/category', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setCategoriesList(JSON.parse(result))
      })
      .catch((error) => console.log('error', error))
  }, [])

  return (
    <div
      id="BlogWrapper"
      className="content-start min-h-screen rainbowBackground"
    >
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Power Platform blog</title>
          <meta
            name="description"
            content={
              userLanguage === 'en'
                ? 'Blog dedicated to Power Apps, Power Automate and related technologies. Created and maintained by Charles GODAERT, expert in these technologies.'
                : 'Blog consacré à Power Apps, Power Automate et aux technologies apparentées. Créé et maintenu par Charles GODAERT, expert spécialisé dans ces technologies.'
            }
          />
          <meta name="robots" content="all" />
        </Helmet>
      </HelmetProvider>
      <div className="flex flex-col w-full py-4 mt-8">
        <Fade duration={1500}>
          <Zoom duration={300}>
            <h1 className="title">Power Platform</h1>
          </Zoom>
        </Fade>
        <Fade delay={200} duration={500}>
          <h2 className="subTitle">
            <Text tid="blog_subtitle" />
          </h2>
          <div className="">
            <h2 className="subTitle">
              <Text tid="blog_inform_title" /> ⬇️
            </h2>
            <div className="flex flex-row w-full h-12 gap-4">
              <input
                type="text"
                placeholder="my@email.com"
                ref={newsletterEmailAddressInputRef}
                className="h-full p-4 text-2xl text-black border border-gray-400 border-solid grow rounded-2xl bg-slate-300 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-transparent"
              />
              <button
                className="h-full px-4 py-0 m-0 text-2xl rainbowButton"
                onClick={saveNewsletterEmailAddress}
              >
                <Text tid="blog_inform_button" /> ✅
              </button>
            </div>
          </div>
        </Fade>
      </div>
      <Fade delay={400} duration={500}>
        <hr className="w-full opacity-70" />
        <div id="ArticlesItems" className="w-full">
          {articlesList.map(
            ({
              _id,
              titleFormatted,
              categories,
              visible,
              titleEn,
              introEn,
              contentEn,
              titleFr,
              introFr,
              contentFr,
            }) =>
              visible &&
              (userLanguage === 'en' ? (
                <ArticleInBlog
                  key={_id}
                  id={_id}
                  titleFormatted={titleFormatted}
                  categories={categories}
                  categoriesList={categoriesList}
                  visible={visible}
                  title={titleEn}
                  intro={introEn}
                  content={contentEn}
                />
              ) : (
                <ArticleInBlog
                  key={_id}
                  id={_id}
                  titleFormatted={titleFormatted}
                  categories={categories}
                  categoriesList={categoriesList}
                  visible={visible}
                  title={titleFr}
                  intro={introFr}
                  content={contentFr}
                />
              ))
          )}
        </div>
      </Fade>
    </div>
  )
}
