import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'

import CategoriesToIcons from '../../components/CategoriesToIcons'
import ArticleComments from '../../components/ArticleComments'
import ShareButtons from '../../components/ShareButtons'
import { LanguageContext } from '../../containers/Language'
import { Text } from '../../containers/Language'
import { config } from '../../resources/Constants'

import './index.scss'

const DOMPurify = require('dompurify')(window)

export default function Article() {
  let articleTitleFormatted = useParams().articleTitleFormatted
  const [article, setArticle] = useState({})
  const [articleReadingTime, setArticleReadingTime] = useState(0)
  const [categoriesList, setCategoriesList] = useState([])

  const { userLanguage } = useContext(LanguageContext)

  // Increment view count
  useEffect(() => {
    let requestOptions = {
      method: 'PUT',
    }

    fetch(
      config.url.API_URL +
        '/api/blog/incrementViewCount/' +
        articleTitleFormatted,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {})
      .catch((error) => console.log('error', error))
  }, [articleTitleFormatted])

  // Get all categories
  useEffect(() => {
    let requestOptions = {
      method: 'GET',
    }

    fetch(config.url.API_URL + '/api/category', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setCategoriesList(JSON.parse(result))
      })
      .catch((error) => console.log('error', error))
  }, [])

  // Get article content
  useEffect(() => {
    if (articleTitleFormatted) {
      let requestOptions = {
        method: 'GET',
      }

      fetch(
        config.url.API_URL + '/api/blog/' + articleTitleFormatted,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const articleData = JSON.parse(result)
          setArticle(articleData)
          setArticleReadingTime(
            Math.ceil(
              readingTimeInMinutes(
                articleData.contentEn + articleData.contentFr
              ) / 2
            )
          )
        })
        .catch((error) => console.log('error', error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const readingTimeInMinutes = (content) => {
    const wordsPerMinute = 215
    const words = content.trim().split(/\s+/).length
    const time = Math.ceil(words / wordsPerMinute)
    return time
  }

  // Format dates to fr or en depending on user language
  const formatDate = (isoFormatDate) => {
    let date = new Date(isoFormatDate)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let dt = date.getDate()

    if (dt < 10) {
      dt = '0' + dt
    }
    if (month < 10) {
      month = '0' + month
    }

    return userLanguage === 'en'
      ? year + '/' + month + '/' + dt
      : dt + '/' + month + '/' + year
  }

  return (
    <div id="ArticleWrapper" className="min-h-screen rainbowBackground">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {userLanguage === 'en' ? article.titleEn : article.titleFr}
          </title>
          <meta
            name="title"
            content={userLanguage === 'en' ? article.titleEn : article.titleFr}
          />
          <meta
            name="description"
            content={userLanguage === 'en' ? article.introEn : article.introFr}
          />
          <meta name="url" content={encodeURIComponent(window.location.href)} />
          <meta name="robots" content="all" />
        </Helmet>
      </HelmetProvider>
      <div
        id="ArticleDetails"
        className="container w-full p-10 mt-32 mb-12 text-2xl text-black bg-white rounded-2xl lg:mt-24"
      >
        <CategoriesToIcons
          categories={article.categories}
          categoriesList={categoriesList}
          size={24}
        />
        <h1 className="text-6xl title">
          {userLanguage === 'en' ? article.titleEn : article.titleFr}
        </h1>
        <h2 className="my-6 text-2xl">
          <Text tid="article_reading_time" />
          &nbsp;:&nbsp;
          {articleReadingTime}
          &nbsp;
          {articleReadingTime > 1 ? 'minutes' : 'minute'}
        </h2>
        <hr className="my-6 border-black" />
        <div id="ArticleContent">
          <p className="flex items-center gap-8 p-4 bg-gray-200 rounded-2xl">
            <div className="text-6xl">💡</div>
            <div>
              {userLanguage === 'en' ? article.introEn : article.introFr}
            </div>
          </p>
          <p
            className="my-6 content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                userLanguage === 'en' ? article.contentEn : article.contentFr,
                { ADD_ATTR: ['target', 'rel'] }
              ),
            }}
          ></p>
        </div>
        <hr className="my-6 border-black" />
        <ShareButtons
          url={encodeURIComponent(window.location.href)}
          text={userLanguage === 'en' ? article.titleEn : article.titleFr}
          hashtags={categoriesList
            .map((category) => category.title.replace(/\s/g, ''))
            .join(',')}
        />
        <div id="ArticleFooter">
          <p className="my-2">
            <Text tid="article_written_by" /> Charles GODAERT -&nbsp;
            {article.lastUpdateDate !== undefined ? (
              <Text tid="article_updated_on" />
            ) : (
              <Text tid="article_published_on" />
            )}
            &nbsp;
            {article.lastUpdateDate !== undefined
              ? formatDate(article.lastUpdateDate)
              : formatDate(article.publicationDate)}
          </p>
          <p className="mt-2">
            <Text tid="article_cat_handing_text" />
          </p>
          <Link to="/contact">
            <button className="ml-0 rainbowButton">
              <Text tid="article_cat_button" />
            </button>
          </Link>
        </div>
      </div>
      {/* <ArticleComments articleTitleFormatted={articleTitleFormatted} /> */}
    </div>
  )
}
