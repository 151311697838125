import React, { useContext } from 'react'

import { LanguageContext } from '../../containers/Language'
import './legal.scss'

export default function Legal() {
  const { userLanguage } = useContext(LanguageContext)

  return (
    <div id="LegalWrapper" className="text-xl rainbowBackground">
      <div className="p-8 m-4 mt-16 leading-8 text-left text-black bg-slate-200 rounded-xl">
        {userLanguage === 'en' ? (
          <div>
            <h2>Definitions</h2>
            <p>
              <b>Customer:</b> any professional or capable natural person within
              the meaning of Articles 1123 et seq. of the Civil Code, or legal
              entity, who visits the Site which is the subject of these general
              conditions.
              <br />
              <b>Provisions and Services:</b>{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              provides Customers with:
            </p>
            <p>
              <b>Content:</b> All the elements constituting the information
              present on the Site, in particular texts - images - videos.
            </p>
            <p>
              <b>Customer Information:</b> Hereinafter referred to as
              "Information (s)" which correspond to all personal data that may
              be held by{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              for the management of your account, customer relationship
              management and for analysis and statistical purposes.
            </p>
            <p>
              <b>User:</b> Internet user connecting, using the above-mentioned
              site.
            </p>
            <p>
              <b>Personal information:</b> "Information that allows, in any form
              whatsoever, directly or not, the identification of individuals to
              whom it applies" (Article 4 of Law No. 78-17 of January 6, 1978).
            </p>
            <p>
              The terms "personal data," "data subject," "sub-processor," and
              "sensitive data" have the meaning defined by the General Data
              Protection Regulation (GDPR: No. 2016-679)
            </p>
            <h2>1. Website presentation.</h2>
            <p>
              Under Article 6 of Law No. 2004-575 of June 21, 2004 for
              confidence in the digital economy, users of the website{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              are informed of the identity of the various parties involved in
              its realization and monitoring:
            </p>
            <p>
              <strong>Owner</strong> : Charles GODAERT - 2 quai Lucien Toulmond,
              13500 Martigues
              <br />
              <strong>Publication Manager</strong>: Charles GODAERT -
              godaert.c@hotmail.com
              <br />
              The person responsible for publication is a natural person or a
              legal entity.
              <strong>Webmaster</strong>: Charles GODAERT -
              godaert.c@hotmail.com
              <br />
              <strong>Hosts</strong>:{' '}
              <ul>
                <li>
                  Vercel Inc. – 340 S Lemon Ave #4133 - CA 91789 Walnut – +1
                  951-383-6898
                </li>
                <li>
                  Fly.io, Inc. – 321 N Clark St Suite 2550, Chicago, IL 60654,
                  US – + 1 312-626-4490
                </li>
              </ul>
              <br />
              <strong>Data Protection Officer</strong>: Charles GODAERT -
              godaert.c@hotmail.com
              <br />
            </p>
            <div ng-bind-html="linkHTML">
              <p>
                These RGPD legal notices are from the{' '}
                <a
                  href="https://fr.orson.io/1371/generateur-mentions-legales"
                  title="free legal notice generator for a website"
                >
                  free legal notice generator for a website
                </a>
              </p>
            </div>
            <h2>
              2. Terms and conditions of use of the site and the services
              offered.
            </h2>
            <p>
              The Site constitutes an intellectual work protected by the
              provisions of the Intellectual Property Code and applicable
              International Regulations. The Customer may not in any way reuse,
              transfer or exploit for its own account all or part of the
              elements or works of the Site.
            </p>
            <p>
              Use of the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              site implies full acceptance of the general conditions of use
              described below. These terms of use may be modified or
              supplemented at any time, so users of the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              site are advised to consult them regularly.
            </p>
            <p>
              This website is normally accessible to users at all times. An
              interruption for technical maintenance may however be decided by{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , which will then endeavor to inform users in advance of the dates
              and times of the intervention. The{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              website is regularly updated by{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              responsible. In the same way, the legal notices may be modified at
              any time: they are nevertheless binding on the user who is invited
              to refer to them as often as possible in order to become aware of
              them.
            </p>
            <h2>3. Description of services provided.</h2>
            <p>
              The purpose of the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              website is to provide information regarding all of the company's
              activities.
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              strives to provide on the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              site information as accurate as possible. However, it cannot be
              held responsible for omissions, inaccuracies and shortcomings in
              the updating, whether they are of its own making or of the making
              of third-party partners who provide it with this information.
            </p>
            <p>
              All information listed on the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              website is provided as an indication, and is subject to change.
              Furthermore, the information on the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              site is not exhaustive. It is given subject to changes having been
              made since it was put online.
            </p>
            <h2>4. Contractual limitations on technical data.</h2>
            <p>
              The site uses JavaScript technology. The website cannot be held
              responsible for material damages related to the use of the site.
              Moreover, the user of the site undertakes to access the site using
              recent equipment, free of viruses and with a last generation
              updated browser The{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              site is hosted by a provider on the territory of the European
              Union in accordance with the provisions of the General Data
              Protection Regulation (GDPR: No. 2016-679)
            </p>
            <p>
              The goal is to provide a service that ensures the best
              accessibility rate. The host ensures the continuity of its service
              24 Hours a day, every day of the year. However, it reserves the
              right to interrupt the hosting service for the shortest possible
              periods of time, in particular for maintenance purposes,
              improvement of its infrastructures, failure of its infrastructures
              or if the Services generate traffic deemed abnormal.
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              and the host cannot be held responsible in the event of
              malfunction of the Internet network, telephone lines or computer
              and telephone equipment related in particular to network
              congestion preventing access to the server.
            </p>
            <h2>5. Intellectual property and counterfeits.</h2>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              owns the intellectual property rights and holds the usage rights
              to all elements accessible on the website, including text, images,
              graphics, logos, videos, icons and sounds. Any reproduction,
              representation, modification, publication, adaptation of all or
              part of the elements of the site, whatever the means or process
              used, is prohibited, except with the prior written permission of :{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              .
            </p>
            <p>
              Any unauthorized exploitation of the site or any of the elements
              it contains will be considered as constituting an infringement and
              prosecuted in accordance with the provisions of Articles L.335-2
              et seq. of the Intellectual Property Code.
            </p>
            <h2>6. Limitations of liability.</h2>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              acts as the publisher of the site.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              is responsible for the quality and truthfulness of the Content it
              publishes.{' '}
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              shall not be held liable for any direct or indirect damage caused
              to the user's equipment when accessing the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              website, and resulting either from the use of equipment that does
              not meet the specifications indicated in point 4, or from the
              appearance of a bug or incompatibility.
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              shall also not be liable for any indirect damages (such as loss of
              business or loss of opportunity) resulting from the use of the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              website. Interactive spaces (possibility of asking questions in
              the contact space) are available to users.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              reserves the right to delete, without prior notice, any content
              posted in this space that contravenes the legislation applicable
              in France, in particular the provisions relating to data
              protection. Where applicable,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              also reserves the possibility of calling into question the civil
              and/or criminal liability of the user, particularly in the event
              of messages of a racist, insulting, defamatory or pornographic
              nature, regardless of the medium used (text, photography ...).
            </p>
            <h2>7. Personal data management.</h2>
            <p>
              The Customer is informed of the regulations concerning marketing
              communication, the law of June 21, 2014 for confidence in the
              Digital Economy, the Data Protection Act of August 06, 2004 as
              well as the General Data Protection Regulation (RGPD: n°
              2016-679).{' '}
            </p>
            <h3>7.1 Persons responsible for the collection of personal data</h3>
            <p>
              For the Personal Data collected in the context of the creation of
              the User's personal account and his navigation on the Site, the
              person responsible for the processing of Personal Data is: Charles
              GODAERT.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              is represented by Charles GODAERT, its legal representative
            </p>
            <p>
              As the person responsible for processing the data it collects,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              undertakes to comply with the framework of the legal provisions in
              force. In particular, it is the Customer's responsibility to
              establish the purposes of its data processing, to provide its
              prospects and customers, from the collection of their consents,
              with complete information on the processing of their personal data
              and to maintain a register of processing in accordance with
              reality. Whenever{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              processes Personal Data,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              takes all reasonable steps to ensure the accuracy and relevance of
              the Personal Data to the purposes for which{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              processes it.
            </p>
            <h3>7.2 Purpose of the data collected</h3>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              is likely to process all or part of the data:
            </p>
            <ul>
              <li>
                to allow navigation on the Site and the management and
                traceability of the services ordered by the user: connection and
                use data of the Site, billing, order history, etc.{' '}
              </li>
              <li>
                to prevent and fight against computer fraud (spamming,
                hacking...): computer equipment used for navigation, IP address,
                password (hashed){' '}
              </li>
              <li>
                to improve navigation on the Site: connection and usage data{' '}
              </li>
              <li>
                to conduct optional satisfaction surveys on{' '}
                <a href="https://www.charles-godaert.com">
                  https://www.charles-godaert.com
                </a>
                : email address{' '}
              </li>
              <li>
                to conduct communication campaigns (sms, email): phone number,
                email address
              </li>
            </ul>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              does not market your personal data, which is therefore only used
              out of necessity or for statistical and analytical purposes.
            </p>
            <h3>7.3 Right of access, rectification and opposition</h3>
            <p>
              In accordance with current European regulations, Users of{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              have the following rights:{' '}
            </p>
            <ul>
              <li>
                right of access (Article 15 RGPD) and rectification (Article 16
                RGPD), update, completeness of Users' data right of blocking or
                erasure of Users' personal data (Article 17 RGPD), when they are
                inaccurate, incomplete, equivocal, outdated, or whose
                collection, use, communication or storage is prohibited{' '}
              </li>
              <li>
                right to withdraw consent at any time (Article 13-2c GDPR){' '}
              </li>
              <li>
                right to restrict the processing of Users' data (Article 18
                GDPR){' '}
              </li>
              <li>
                right to object to the processing of Users' data (Article 21
                GDPR){' '}
              </li>
              <li>
                right to the portability of data that Users will have provided,
                when such data is subject to automated processing based on their
                consent or on a contract (Article 20 RGPD){' '}
              </li>
              <li>
                right to define the fate of Users' data after their death and to
                choose to whom{' '}
                <a href="https://www.charles-godaert.com">
                  https://www.charles-godaert.com
                </a>{' '}
                shall communicate (or not) their data to a third party they have
                previously designated
              </li>
            </ul>
            <p>
              As soon as{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              becomes aware of a User's death and in the absence of instructions
              from the User,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              undertakes to destroy the User's data, unless its retention is
              necessary for evidentiary purposes or to comply with a legal
              obligation.
            </p>
            <p>
              If the User wishes to know how{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              uses their Personal Data, ask to rectify it or object to its
              processing, the User may contact{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              in writing at the following address:{' '}
            </p>
            Charles GODAERT - DPO, Charles GODAERT <br />2 quai Lucien Toulmond,
            13500 Martigues.
            <p>
              In this case, the User must indicate the Personal Data that he or
              she would like{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              to correct, update or delete, identifying himself or herself
              precisely with a copy of an identification document (identity card
              or passport).{' '}
            </p>
            <p>
              Requests for deletion of Personal Data will be subject to the
              obligations that are imposed on{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              by law, in particular with regard to the retention or archiving of
              documents. Finally, Users of{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              may file a complaint with the supervisory authorities, and in
              particular with the CNIL (https://www.cnil.fr/fr/plaintes).
            </p>
            <h3>7.4 Non-disclosure of personal data</h3>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              shall not process, host or transfer the Information collected on
              its Customers to a country located outside the European Union or
              recognized as "non-adequate" by the European Commission without
              informing the Customer in advance. For all that,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              remains free to choose its technical and commercial subcontractors
              provided that they present sufficient guarantees with regard to
              the requirements of the General Data Protection Regulation (GDPR:
              No. 2016-679).
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              undertakes to take all necessary precautions to preserve the
              security of the Information and, in particular, to ensure that it
              is not disclosed to unauthorized persons. However, if an incident
              impacting the integrity or confidentiality of the Customer's
              Information is brought to the attention of{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , the latter shall as soon as possible inform the Customer and
              communicate the corrective measures taken. Furthermore{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              does not collect any "sensitive data."
            </p>
            <p>
              The User's Personal Data may be processed by{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              subsidiaries and subcontractors (service providers), exclusively
              in order to achieve the purposes of this policy.
            </p>
            <p>
              Within the limits of their respective attributions and for the
              purposes recalled above, the main persons likely to have access to
              the data of{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              Users are mainly the agents of our customer service.
            </p>
            <div ng-bind-html="rgpdHTML"></div>
            <h2>8. Incident Notification</h2>
            <p>
              Despite our best efforts, no method of transmission over the
              Internet and no method of electronic storage is completely secure.
              Therefore, we cannot guarantee absolute security. If we become
              aware of a security breach, we will notify affected users so that
              they can take appropriate action. Our incident notification
              procedures take into account our legal obligations, whether at
              national or European level. We are committed to fully informing
              our customers of all matters pertaining to their account security
              and providing them with all the information necessary to help them
              meet their own regulatory reporting obligations.
            </p>
            <p>
              No personal information of the user of the site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              is published without the user's knowledge, exchanged, transferred,
              ceded or sold on any medium to third parties. Only the assumption
              of the repurchase of{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              and its rights would allow the transmission of the said
              information to the eventual purchaser who would in turn be bound
              by the same obligation of conservation and modification of the
              data with respect to the user of the site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              .
            </p>
            <h3>Security</h3>
            <p>
              To ensure the security and confidentiality of Personal Data and
              Personal Health Data,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              uses networks protected by standard features such as firewalls,
              pseudonymization, encryption and password protection.{' '}
            </p>
            <p>
              When processing Personal Data,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              takes all reasonable steps to protect it from loss, misuse,
              unauthorized access, disclosure, alteration or destruction.
            </p>
            <h2>9. Hypertext links "cookies" and internet tags ("tags")</h2>
            <p>
              The{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              site contains a number of hyperlinks to other sites, set up with
              the permission of{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              . However,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              does not have the possibility to check the content of the sites
              thus visited, and will consequently not assume any responsibility
              for this.
            </p>
            Unless you decide to deactivate the cookies, you accept that the
            site can use them. You can deactivate these cookies at any time,
            free of charge, using the deactivation options offered to you and
            recalled below, knowing that this may reduce or prevent access to
            all or part of the Services offered by the site.
            <p></p>
            <h3>9.1. "COOKIES"</h3>
            <p>
              A "cookie" is a small information file sent to the User's browser
              and stored within the User's terminal (e.g. computer, smartphone),
              (hereinafter "Cookies"). This file includes information such as
              the User's domain name, the User's Internet service provider, the
              User's operating system, and the date and time of access. Cookies
              are in no way likely to damage the User's terminal.
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              is likely to process the User's information concerning his visit
              to the Site, such as the pages consulted, the searches performed.
              This information allows{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              to improve the content of the Site, the User's navigation.
            </p>
            <p>
              Since Cookies facilitate navigation and/or the provision of
              services offered by the Site, the User may configure his browser
              to allow him to decide whether or not he wishes to accept them so
              that Cookies are recorded in the terminal or, on the contrary,
              that they are rejected, either systematically or according to
              their sender. The User may also configure his or her browsing
              software so that acceptance or rejection of Cookies is offered to
              him or her from time to time, before a Cookie is likely to be
              recorded in his or her terminal.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              informs the User that, in this case, not all of the
              functionalities of his or her browser software may be available.
            </p>
            <p>
              If the User refuses the storage of Cookies in his terminal or
              browser, or if the User deletes those stored there, the User is
              informed that his navigation and experience on the Site may be
              limited. This could also be the case when{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              or one of its providers cannot recognize, for technical
              compatibility purposes, the type of browser used by the terminal,
              the language and display settings or the country from which the
              terminal appears to be connected to the Internet.
            </p>
            <p>
              Where applicable,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              declines all responsibility for the consequences related to the
              degraded functioning of the Site and any services offered by{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , resulting from (i) the refusal of Cookies by the User (ii) the
              impossibility for{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              to save or consult the Cookies necessary for their functioning due
              to the User's choice. For the management of Cookies and the User's
              choices, the configuration of each browser is different. It is
              described in the browser's help menu, which will show how the User
              can change his wishes regarding Cookies.
            </p>
            <p>
              At any time, the User may make the choice to express and modify
              his or her wishes regarding Cookies.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              may additionally use the services of external service providers to
              assist in collecting and processing the information described in
              this section.
            </p>
            <p>
              Finally, by clicking on the icons dedicated to the social networks
              Twitter, Facebook, Linkedin and Google Plus appearing on the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              Website or in its mobile application and if the User has accepted
              the deposit of cookies by continuing to navigate on the{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , Twitter, Facebook, Linkedin and Google Plus may also deposit
              cookies on your terminals (computer, tablet, cell phone).
            </p>
            <p>
              These types of cookies are deposited on your terminals only if you
              consent to them, by continuing your navigation on the Website or
              mobile application of{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              . At any time, however, the User may revoke his or her consent for{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              to deposit such cookies.
            </p>
            <h3>Article 9.2. INTERNET TAGS</h3>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              may occasionally employ Internet tags (also known as "tags," or
              action tags, single-pixel GIFs, clear GIFs, invisible GIFs, and
              one-to-one GIFs) and deploy them through a web analytics partner
              that may be located (and thus store related information, including
              the User's IP address) in a foreign country.
            </p>
            <p>
              These tags are placed both in the online advertisements that allow
              users to access the Site, and on the various pages of the Site.
            </p>
            <p>
              This technology allows{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              to evaluate visitors' responses to the Site and the effectiveness
              of its actions (e.g., the number of times a page is opened and the
              information viewed), as well as the User's use of this Site.{' '}
            </p>
            <p>
              The External Provider may collect information about visitors to
              the Site and other websites through these tags, compile reports on
              Site activity for{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , and provide other services relating to the use of the Site and
              the Internet.
            </p>
            <p></p>
            <h2>10. Applicable law and jurisdiction.</h2>
            <p>
              Any dispute in connection with the use of the site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              is subject to French law. Except in cases where the law does not
              allow it, exclusive jurisdiction is given to the competent courts
              of Aix-en-Provence
            </p>
          </div>
        ) : (
          <div>
            <h2>Définitions</h2>
            <p>
              <b>Client :</b> tout professionnel ou personne physique capable au
              sens des articles 1123 et suivants du Code civil, ou personne
              morale, qui visite le Site objet des présentes conditions
              générales.
              <br />
              <b>Prestations et Services :</b>{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              met à disposition des Clients :
            </p>
            <p>
              <b>Contenu :</b> Ensemble des éléments constituants l’information
              présente sur le Site, notamment textes – images – vidéos.
            </p>
            <p>
              <b>Informations clients :</b> Ci après dénommé « Information (s) »
              qui correspondent à l’ensemble des données personnelles
              susceptibles d’être détenues par{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              pour la gestion de votre compte, de la gestion de la relation
              client et à des fins d’analyses et de statistiques.
            </p>
            <p>
              <b>Utilisateur :</b> Internaute se connectant, utilisant le site
              susnommé.
            </p>
            <p>
              <b>Informations personnelles :</b> « Les informations qui
              permettent, sous quelque forme que ce soit, directement ou non,
              l'identification des personnes physiques auxquelles elles
              s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
            </p>
            <p>
              Les termes « données à caractère personnel », « personne concernée
              », « sous traitant » et « données sensibles » ont le sens défini
              par le Règlement Général sur la Protection des Données (RGPD : n°
              2016-679)
            </p>
            <h2>1. Présentation du site internet.</h2>
            <p>
              En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour
              la confiance dans l'économie numérique, il est précisé aux
              utilisateurs du site internet{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              l'identité des différents intervenants dans le cadre de sa
              réalisation et de son suivi:
            </p>
            <p>
              <strong>Propriétaire</strong> : Charles GODAERT – 2 quai Lucien
              Toulmond, 13500 Martigues
              <br />
              <strong>Responsable publication</strong> : Charles GODAERT –
              godaert.c@hotmail.com
              <br />
              Le responsable publication est une personne physique ou une
              personne morale.
              <br />
              <strong>Webmaster</strong> : Charles GODAERT –
              godaert.c@hotmail.com
              <br />
              <strong>Hébergeurs</strong> :
              <ul>
                <li>
                  Vercel Inc. – 340 S Lemon Ave #4133 - CA 91789 Walnut – +1
                  951-383-6898
                </li>
                <li>
                  Fly.io, Inc. – 321 N Clark St Suite 2550, Chicago, IL 60654,
                  US – + 1 312-626-4490
                </li>
              </ul>
              <br />
              <strong>Délégué à la protection des données</strong> : Charles
              GODAERT – godaert.c@hotmail.com
              <br />
            </p>
            <div ng-bind-html="linkHTML">
              <p>
                Ces mentions légales RGPD sont issues du{' '}
                <a
                  href="https://fr.orson.io/1371/generateur-mentions-legales"
                  title="générateur gratuit de mentions légales pour un site internet"
                >
                  générateur gratuit de mentions légales pour un site internet
                </a>
              </p>
            </div>
            <h2>
              2. Conditions générales d’utilisation du site et des services
              proposés.
            </h2>
            <p>
              Le Site constitue une œuvre de l’esprit protégée par les
              dispositions du Code de la Propriété Intellectuelle et des
              Réglementations Internationales applicables. Le Client ne peut en
              aucune manière réutiliser, céder ou exploiter pour son propre
              compte tout ou partie des éléments ou travaux du Site.
            </p>
            <p>
              L’utilisation du site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              implique l’acceptation pleine et entière des conditions générales
              d’utilisation ci-après décrites. Ces conditions d’utilisation sont
              susceptibles d’être modifiées ou complétées à tout moment, les
              utilisateurs du site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              sont donc invités à les consulter de manière régulière.
            </p>
            <p>
              Ce site internet est normalement accessible à tout moment aux
              utilisateurs. Une interruption pour raison de maintenance
              technique peut être toutefois décidée par{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , qui s’efforcera alors de communiquer préalablement aux
              utilisateurs les dates et heures de l’intervention. Le site web{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              est mis à jour régulièrement par{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              responsable. De la même façon, les mentions légales peuvent être
              modifiées à tout moment : elles s’imposent néanmoins à
              l’utilisateur qui est invité à s’y référer le plus souvent
              possible afin d’en prendre connaissance.
            </p>
            <h2>3. Description des services fournis.</h2>
            <p>
              Le site internet{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              a pour objet de fournir une information concernant l’ensemble des
              activités de la société.
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              s’efforce de fournir sur le site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              des informations aussi précises que possible. Toutefois, il ne
              pourra être tenu responsable des oublis, des inexactitudes et des
              carences dans la mise à jour, qu’elles soient de son fait ou du
              fait des tiers partenaires qui lui fournissent ces informations.
            </p>
            <p>
              Toutes les informations indiquées sur le site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              sont données à titre indicatif, et sont susceptibles d’évoluer.
              Par ailleurs, les renseignements figurant sur le site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              ne sont pas exhaustifs. Ils sont donnés sous réserve de
              modifications ayant été apportées depuis leur mise en ligne.
            </p>
            <h2>4. Limitations contractuelles sur les données techniques.</h2>
            <p>
              Le site utilise la technologie JavaScript. Le site Internet ne
              pourra être tenu responsable de dommages matériels liés à
              l’utilisation du site. De plus, l’utilisateur du site s’engage à
              accéder au site en utilisant un matériel récent, ne contenant pas
              de virus et avec un navigateur de dernière génération mis-à-jour
              Le site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              est hébergé chez un prestataire sur le territoire de l’Union
              Européenne conformément aux dispositions du Règlement Général sur
              la Protection des Données (RGPD : n° 2016-679)
            </p>
            <p>
              L’objectif est d’apporter une prestation qui assure le meilleur
              taux d’accessibilité. L’hébergeur assure la continuité de son
              service 24 Heures sur 24, tous les jours de l’année. Il se réserve
              néanmoins la possibilité d’interrompre le service d’hébergement
              pour les durées les plus courtes possibles notamment à des fins de
              maintenance, d’amélioration de ses infrastructures, de défaillance
              de ses infrastructures ou si les Prestations et Services génèrent
              un trafic réputé anormal.
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              et l’hébergeur ne pourront être tenus responsables en cas de
              dysfonctionnement du réseau Internet, des lignes téléphoniques ou
              du matériel informatique et de téléphonie lié notamment à
              l’encombrement du réseau empêchant l’accès au serveur.
            </p>
            <h2>5. Propriété intellectuelle et contrefaçons.</h2>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              est propriétaire des droits de propriété intellectuelle et détient
              les droits d’usage sur tous les éléments accessibles sur le site
              internet, notamment les textes, images, graphismes, logos, vidéos,
              icônes et sons. Toute reproduction, représentation, modification,
              publication, adaptation de tout ou partie des éléments du site,
              quel que soit le moyen ou le procédé utilisé, est interdite, sauf
              autorisation écrite préalable de :{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              .
            </p>
            <p>
              Toute exploitation non autorisée du site ou de l’un quelconque des
              éléments qu’il contient sera considérée comme constitutive d’une
              contrefaçon et poursuivie conformément aux dispositions des
              articles L.335-2 et suivants du Code de Propriété Intellectuelle.
            </p>
            <h2>6. Limitations de responsabilité.</h2>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              agit en tant qu’éditeur du site.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              est responsable de la qualité et de la véracité du Contenu qu’il
              publie.{' '}
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              ne pourra être tenu responsable des dommages directs et indirects
              causés au matériel de l’utilisateur, lors de l’accès au site
              internet{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , et résultant soit de l’utilisation d’un matériel ne répondant
              pas aux spécifications indiquées au point 4, soit de l’apparition
              d’un bug ou d’une incompatibilité.
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              ne pourra également être tenu responsable des dommages indirects
              (tels par exemple qu’une perte de marché ou perte d’une chance)
              consécutifs à l’utilisation du site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              . Des espaces interactifs (possibilité de poser des questions dans
              l’espace contact) sont à la disposition des utilisateurs.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              se réserve le droit de supprimer, sans mise en demeure préalable,
              tout contenu déposé dans cet espace qui contreviendrait à la
              législation applicable en France, en particulier aux dispositions
              relatives à la protection des données. Le cas échéant,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              se réserve également la possibilité de mettre en cause la
              responsabilité civile et/ou pénale de l’utilisateur, notamment en
              cas de message à caractère raciste, injurieux, diffamant, ou
              pornographique, quel que soit le support utilisé (texte,
              photographie …).
            </p>
            <h2>7. Gestion des données personnelles.</h2>
            <p>
              Le Client est informé des réglementations concernant la
              communication marketing, la loi du 21 Juin 2014 pour la confiance
              dans l’Economie Numérique, la Loi Informatique et Liberté du 06
              Août 2004 ainsi que du Règlement Général sur la Protection des
              Données (RGPD : n° 2016-679).{' '}
            </p>
            <h3>7.1 Responsables de la collecte des données personnelles</h3>
            <p>
              Pour les Données Personnelles collectées dans le cadre de la
              création du compte personnel de l’Utilisateur et de sa navigation
              sur le Site, le responsable du traitement des Données Personnelles
              est : Charles GODAERT.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              est représenté par Charles GODAERT, son représentant légal
            </p>
            <p>
              En tant que responsable du traitement des données qu’il collecte,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              s’engage à respecter le cadre des dispositions légales en vigueur.
              Il lui appartient notamment au Client d’établir les finalités de
              ses traitements de données, de fournir à ses prospects et clients,
              à partir de la collecte de leurs consentements, une information
              complète sur le traitement de leurs données personnelles et de
              maintenir un registre des traitements conforme à la réalité.
              Chaque fois que{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              traite des Données Personnelles,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              prend toutes les mesures raisonnables pour s’assurer de
              l’exactitude et de la pertinence des Données Personnelles au
              regard des finalités pour lesquelles{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              les traite.
            </p>
            <h3>7.2 Finalité des données collectées</h3>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              est susceptible de traiter tout ou partie des données :{' '}
            </p>
            <ul>
              <li>
                pour permettre la navigation sur le Site et la gestion et la
                traçabilité des prestations et services commandés par
                l’utilisateur : données de connexion et d’utilisation du Site,
                facturation, historique des commandes, etc.{' '}
              </li>

              <li>
                pour prévenir et lutter contre la fraude informatique (spamming,
                hacking…) : matériel informatique utilisé pour la navigation,
                l’adresse IP, le mot de passe (hashé){' '}
              </li>

              <li>
                pour améliorer la navigation sur le Site : données de connexion
                et d’utilisation{' '}
              </li>

              <li>
                pour mener des enquêtes de satisfaction facultatives sur{' '}
                <a href="https://www.charles-godaert.com">
                  https://www.charles-godaert.com
                </a>{' '}
                : adresse email{' '}
              </li>
              <li>
                pour mener des campagnes de communication (sms, mail) : numéro
                de téléphone, adresse email
              </li>
            </ul>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              ne commercialise pas vos données personnelles qui sont donc
              uniquement utilisées par nécessité ou à des fins statistiques et
              d’analyses.
            </p>
            <h3>7.3 Droit d’accès, de rectification et d’opposition</h3>
            <p>
              Conformément à la réglementation européenne en vigueur, les
              Utilisateurs de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              disposent des droits suivants :{' '}
            </p>
            <ul>
              <li>
                droit d'accès (article 15 RGPD) et de rectification (article 16
                RGPD), de mise à jour, de complétude des données des
                Utilisateurs droit de verrouillage ou d’effacement des données
                des Utilisateurs à caractère personnel (article 17 du RGPD),
                lorsqu’elles sont inexactes, incomplètes, équivoques, périmées,
                ou dont la collecte, l'utilisation, la communication ou la
                conservation est interdite{' '}
              </li>

              <li>
                droit de retirer à tout moment un consentement (article 13-2c
                RGPD){' '}
              </li>

              <li>
                droit à la limitation du traitement des données des Utilisateurs
                (article 18 RGPD){' '}
              </li>

              <li>
                droit d’opposition au traitement des données des Utilisateurs
                (article 21 RGPD){' '}
              </li>

              <li>
                droit à la portabilité des données que les Utilisateurs auront
                fournies, lorsque ces données font l’objet de traitements
                automatisés fondés sur leur consentement ou sur un contrat
                (article 20 RGPD){' '}
              </li>

              <li>
                droit de définir le sort des données des Utilisateurs après leur
                mort et de choisir à qui{' '}
                <a href="https://www.charles-godaert.com">
                  https://www.charles-godaert.com
                </a>{' '}
                devra communiquer (ou non) ses données à un tiers qu’ils aura
                préalablement désigné
              </li>
            </ul>
            <p>
              Dès que{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              a connaissance du décès d’un Utilisateur et à défaut
              d’instructions de sa part,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              s’engage à détruire ses données, sauf si leur conservation s’avère
              nécessaire à des fins probatoires ou pour répondre à une
              obligation légale.
            </p>
            <p>
              Si l’Utilisateur souhaite savoir comment{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              utilise ses Données Personnelles, demander à les rectifier ou
              s’oppose à leur traitement, l’Utilisateur peut contacter{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              par écrit à l’adresse suivante :{' '}
            </p>
            Charles GODAERT – DPO, Charles GODAERT <br />2 quai Lucien Toulmond,
            13500 Martigues.
            <p>
              Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles
              qu’il souhaiterait que{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              corrige, mette à jour ou supprime, en s’identifiant précisément
              avec une copie d’une pièce d’identité (carte d’identité ou
              passeport).{' '}
            </p>
            <p>
              Les demandes de suppression de Données Personnelles seront
              soumises aux obligations qui sont imposées à{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              par la loi, notamment en matière de conservation ou d’archivage
              des documents. Enfin, les Utilisateurs de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              peuvent déposer une réclamation auprès des autorités de contrôle,
              et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).
            </p>
            <h3>7.4 Non-communication des données personnelles</h3>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              s’interdit de traiter, héberger ou transférer les Informations
              collectées sur ses Clients vers un pays situé en dehors de l’Union
              européenne ou reconnu comme « non adéquat » par la Commission
              européenne sans en informer préalablement le client. Pour autant,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              reste libre du choix de ses sous-traitants techniques et
              commerciaux à la condition qu’il présentent les garanties
              suffisantes au regard des exigences du Règlement Général sur la
              Protection des Données (RGPD : n° 2016-679).
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              s’engage à prendre toutes les précautions nécessaires afin de
              préserver la sécurité des Informations et notamment qu’elles ne
              soient pas communiquées à des personnes non autorisées. Cependant,
              si un incident impactant l’intégrité ou la confidentialité des
              Informations du Client est portée à la connaissance de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , celle-ci devra dans les meilleurs délais informer le Client et
              lui communiquer les mesures de corrections prises. Par ailleurs{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              ne collecte aucune « données sensibles ».
            </p>
            <p>
              Les Données Personnelles de l’Utilisateur peuvent être traitées
              par des filiales de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              et des sous-traitants (prestataires de services), exclusivement
              afin de réaliser les finalités de la présente politique.
            </p>
            <p>
              Dans la limite de leurs attributions respectives et pour les
              finalités rappelées ci-dessus, les principales personnes
              susceptibles d’avoir accès aux données des Utilisateurs de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              sont principalement les agents de notre service client.
            </p>
            <div ng-bind-html="rgpdHTML"></div>
            <h2>8. Notification d’incident</h2>
            <p>
              Quels que soient les efforts fournis, aucune méthode de
              transmission sur Internet et aucune méthode de stockage
              électronique n'est complètement sûre. Nous ne pouvons en
              conséquence pas garantir une sécurité absolue. Si nous prenions
              connaissance d'une brèche de la sécurité, nous avertirions les
              utilisateurs concernés afin qu'ils puissent prendre les mesures
              appropriées. Nos procédures de notification d’incident tiennent
              compte de nos obligations légales, qu'elles se situent au niveau
              national ou européen. Nous nous engageons à informer pleinement
              nos clients de toutes les questions relevant de la sécurité de
              leur compte et à leur fournir toutes les informations nécessaires
              pour les aider à respecter leurs propres obligations
              réglementaires en matière de reporting.
            </p>
            <p>
              Aucune information personnelle de l'utilisateur du site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              n'est publiée à l'insu de l'utilisateur, échangée, transférée,
              cédée ou vendue sur un support quelconque à des tiers. Seule
              l'hypothèse du rachat de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              et de ses droits permettrait la transmission des dites
              informations à l'éventuel acquéreur qui serait à son tour tenu de
              la même obligation de conservation et de modification des données
              vis à vis de l'utilisateur du site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              .
            </p>
            <h3>Sécurité</h3>
            <p>
              Pour assurer la sécurité et la confidentialité des Données
              Personnelles et des Données Personnelles de Santé,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              utilise des réseaux protégés par des dispositifs standards tels
              que par pare-feu, la pseudonymisation, l’encryption et mot de
              passe.{' '}
            </p>
            <p>
              Lors du traitement des Données Personnelles,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              prend toutes les mesures raisonnables visant à les protéger contre
              toute perte, utilisation détournée, accès non autorisé,
              divulgation, altération ou destruction.
            </p>
            <h2>
              9. Liens hypertextes « cookies » et balises (“tags”) internet
            </h2>
            <p>
              Le site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              contient un certain nombre de liens hypertextes vers d’autres
              sites, mis en place avec l’autorisation de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              . Cependant,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              n’a pas la possibilité de vérifier le contenu des sites ainsi
              visités, et n’assumera en conséquence aucune responsabilité de ce
              fait.
            </p>
            Sauf si vous décidez de désactiver les cookies, vous acceptez que le
            site puisse les utiliser. Vous pouvez à tout moment désactiver ces
            cookies et ce gratuitement à partir des possibilités de
            désactivation qui vous sont offertes et rappelées ci-après, sachant
            que cela peut réduire ou empêcher l’accessibilité à tout ou partie
            des Services proposés par le site.
            <p></p>
            <h3>9.1. « COOKIES »</h3>
            <p>
              Un « cookie » est un petit fichier d’information envoyé sur le
              navigateur de l’Utilisateur et enregistré au sein du terminal de
              l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies
              »). Ce fichier comprend des informations telles que le nom de
              domaine de l’Utilisateur, le fournisseur d’accès Internet de
              l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi
              que la date et l’heure d’accès. Les Cookies ne risquent en aucun
              cas d’endommager le terminal de l’Utilisateur.
            </p>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              est susceptible de traiter les informations de l’Utilisateur
              concernant sa visite du Site, telles que les pages consultées, les
              recherches effectuées. Ces informations permettent à{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              d’améliorer le contenu du Site, de la navigation de l’Utilisateur.
            </p>
            <p>
              Les Cookies facilitant la navigation et/ou la fourniture des
              services proposés par le Site, l’Utilisateur peut configurer son
              navigateur pour qu’il lui permette de décider s’il souhaite ou non
              les accepter de manière à ce que des Cookies soient enregistrés
              dans le terminal ou, au contraire, qu’ils soient rejetés, soit
              systématiquement, soit selon leur émetteur. L’Utilisateur peut
              également configurer son logiciel de navigation de manière à ce
              que l’acceptation ou le refus des Cookies lui soient proposés
              ponctuellement, avant qu’un Cookie soit susceptible d’être
              enregistré dans son terminal.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              informe l’Utilisateur que, dans ce cas, il se peut que les
              fonctionnalités de son logiciel de navigation ne soient pas toutes
              disponibles.
            </p>
            <p>
              Si l’Utilisateur refuse l’enregistrement de Cookies dans son
              terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui
              y sont enregistrés, l’Utilisateur est informé que sa navigation et
              son expérience sur le Site peuvent être limitées. Cela pourrait
              également être le cas lorsque{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              ou l’un de ses prestataires ne peut pas reconnaître, à des fins de
              compatibilité technique, le type de navigateur utilisé par le
              terminal, les paramètres de langue et d’affichage ou le pays
              depuis lequel le terminal semble connecté à Internet.
            </p>
            <p>
              Le cas échéant,{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              décline toute responsabilité pour les conséquences liées au
              fonctionnement dégradé du Site et des services éventuellement
              proposés par{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , résultant (i) du refus de Cookies par l’Utilisateur (ii) de
              l’impossibilité pour{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              d’enregistrer ou de consulter les Cookies nécessaires à leur
              fonctionnement du fait du choix de l’Utilisateur. Pour la gestion
              des Cookies et des choix de l’Utilisateur, la configuration de
              chaque navigateur est différente. Elle est décrite dans le menu
              d’aide du navigateur, qui permettra de savoir de quelle manière
              l’Utilisateur peut modifier ses souhaits en matière de Cookies.
            </p>
            <p>
              À tout moment, l’Utilisateur peut faire le choix d’exprimer et de
              modifier ses souhaits en matière de Cookies.{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              pourra en outre faire appel aux services de prestataires externes
              pour l’aider à recueillir et traiter les informations décrites
              dans cette section.
            </p>
            <p>
              Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux
              Twitter, Facebook, Linkedin et Google Plus figurant sur le Site de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              ou dans son application mobile et si l’Utilisateur a accepté le
              dépôt de cookies en poursuivant sa navigation sur le Site Internet
              ou l’application mobile de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , Twitter, Facebook, Linkedin et Google Plus peuvent également
              déposer des cookies sur vos terminaux (ordinateur, tablette,
              téléphone portable).
            </p>
            <p>
              Ces types de cookies ne sont déposés sur vos terminaux qu’à
              condition que vous y consentiez, en continuant votre navigation
              sur le Site Internet ou l’application mobile de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              . À tout moment, l’Utilisateur peut néanmoins revenir sur son
              consentement à ce que{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              dépose ce type de cookies.
            </p>
            <h3>Article 9.2. BALISES (“TAGS”) INTERNET</h3>
            <p>
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              peut employer occasionnellement des balises Internet (également
              appelées « tags », ou balises d’action, GIF à un pixel, GIF
              transparents, GIF invisibles et GIF un à un) et les déployer par
              l’intermédiaire d’un partenaire spécialiste d’analyses Web
              susceptible de se trouver (et donc de stocker les informations
              correspondantes, y compris l’adresse IP de l’Utilisateur) dans un
              pays étranger.
            </p>
            <p>
              Ces balises sont placées à la fois dans les publicités en ligne
              permettant aux internautes d’accéder au Site, et sur les
              différentes pages de celui-ci.
            </p>
            <p>
              Cette technologie permet à{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              d’évaluer les réponses des visiteurs face au Site et l’efficacité
              de ses actions (par exemple, le nombre de fois où une page est
              ouverte et les informations consultées), ainsi que l’utilisation
              de ce Site par l’Utilisateur.{' '}
            </p>
            <p>
              Le prestataire externe pourra éventuellement recueillir des
              informations sur les visiteurs du Site et d’autres sites Internet
              grâce à ces balises, constituer des rapports sur l’activité du
              Site à l’attention de{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>
              , et fournir d’autres services relatifs à l’utilisation de
              celui-ci et d’Internet.
            </p>
            <p></p>
            <h2>10. Droit applicable et attribution de juridiction.</h2>
            <p>
              Tout litige en relation avec l’utilisation du site{' '}
              <a href="https://www.charles-godaert.com">
                https://www.charles-godaert.com
              </a>{' '}
              est soumis au droit français. En dehors des cas où la loi ne le
              permet pas, il est fait attribution exclusive de juridiction aux
              tribunaux compétents de Aix-en-Provence
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
