import React, { useState, createContext, useContext } from 'react'

import { languageOptions, dictionaryList } from '../languages'

let navigatorUserLangCode = getNavigatorLangCode()
let navigatorUserLangDict = getNavigatorLangDict()

export const LanguageContext = createContext({
  userLanguage: navigatorUserLangCode,
  dictionary: navigatorUserLangDict,
})

function getNavigatorLangCode() {
  let code = (
    window.navigator.language || window.navigator.userLanguage
  ).substring(0, 2)
  // If navigator lang does not exist in available options, set as french
  if (!languageOptions[code]) {
    code = 'fr'
  }
  return code
}

function getNavigatorLangDict() {
  // If navigator lang does not exist in available options, set as french
  return dictionaryList[navigatorUserLangCode]
    ? dictionaryList[navigatorUserLangCode]
    : dictionaryList.fr
}

// it provides the language context to app
export function LanguageProvider({ children }) {
  const defaultLanguage = window.localStorage.getItem('rcml-lang')
  const [userLanguage, setUserLanguage] = useState(
    defaultLanguage || navigatorUserLangCode
  )

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected]
        ? selected
        : navigatorUserLangCode
      setUserLanguage(newLanguage)
      window.localStorage.setItem('rcml-lang', newLanguage)
    },
  }

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}

// get text according to id & current language
export function Text({ tid }) {
  const languageContext = useContext(LanguageContext)
  return languageContext.dictionary[tid] || tid
}

// get text according to id & current language
export function Html({ tid }) {
  const DOMPurify = require('dompurify')(window)

  const languageContext = useContext(LanguageContext)

  let text = languageContext.dictionary[tid] || tid

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(text),
      }}
    ></span>
  )
}
