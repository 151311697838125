import React from 'react'
import { Text } from '../containers/Language'

import LinkedInIcon from '../assets/linkedin_icon.svg'
import TwitterIcon from '../assets/twitter_icon.svg'

export default function ShareButtons({ url, text, hashtags }) {
  // const handleShareClick = (e) => {
  //   e.preventDefault()
  //   // Share article on social media

  //   const shareData = {
  //     title: article.title,
  //     text: article.intro,
  //     url: window.location.href,
  //   }

  //   navigator.share(shareData)
  // }

  /*
  const handleShareArticleClick = (e) => {
    e.preventDefault()
    // Share article on social media

    var url = window.location.href
    var title = article.title
    var description = article.intro

    var twitter =
      'https://twitter.com/intent/tweet?text=' +
      title +
      '&url=' +
      url +
      '&via=&hashtags='
    var facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + url
    var linkedin =
      'https://www.linkedin.com/shareArticle?mini=true&url=' +
      url +
      '&title=' +
      title +
      '&summary=' +
      description +
      '&source='
  }
*/

  return (
    <div className="flex items-center gap-5 mt-4 mb-8">
      <p className="text-3xl">
        <Text tid="article_share" />
      </p>
      {/* <button onClick={handleShareClick}>Test Web Share API</button> */}
      <a
        className="flex items-center group"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
      >
        <img
          className="w-10 h-10 transition-transform group-hover:-rotate-12"
          alt="Share on LinkedIn"
          src={LinkedInIcon}
        />
      </a>
      <a
        className="flex items-center group"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://twitter.com/intent/tweet?url=${
          encodeURIComponent(window.location.href) + '%0a'
        }&text=${text + '%0a'}&hashtags=${hashtags}`}
      >
        <img
          className="w-10 h-10 transition-transform group-hover:-rotate-12"
          alt="Share on Twitter"
          src={TwitterIcon}
        />
      </a>
    </div>
  )
}
