import { NavLink } from 'react-router-dom'
import './styledNavLink.scss'

export default function StyledNavLink({ url, text }) {
  return (
    <NavLink
      to={url}
      className={({ isActive }) =>
        (isActive ? 'activeLinkStyle' : '') +
        ' px-8 pt-8 pb-4 w-40 text-center relative after:bottom-0 after:h-0.5 after:block after:left-2/4 after:absolute after:bg-white after:w-0 after:transition-[width,left] after:duration-300 hover:after:w-full hover:after:left-0'
      }
    >
      {text}
    </NavLink>
  )
}
