import LanguageSelector from './LanguageSelector'
import StyledNavLink from './StyledNavLink'

export default function Header() {
  return (
    <div
      id="Header"
      className="absolute flex items-center justify-center w-full text-2xl font-medium text-white"
    >
      <StyledNavLink url="/" text="Accueil" />
      <StyledNavLink url="/blog" text="Blog" />

      <div className="absolute px-8 pt-8 pb-4 right-1">
        <LanguageSelector />
      </div>
    </div>
  )
}
