import React from 'react'
import { Link } from 'react-router-dom'

import CategoriesToIcons from './CategoriesToIcons'

export default function ArticleInBlog({
  id,
  titleFormatted,
  categories,
  categoriesList,
  visible,
  title,
  intro,
}) {
  return (
    <span>
      <Link to={`/article/` + titleFormatted}>
        <div className="py-4 border-b border-white border-solid cursor-pointer articleItem border-opacity-70 group">
          <CategoriesToIcons
            categories={categories}
            categoriesList={categoriesList}
            size={12}
          />
          <h1
            className="relative my-4 text-4xl w-fit 
                        after:absolute after:-bottom-1 after:block after:h-0.5 after:bg-white after:w-0 after:transition-width after:duration-300 
                        group-hover:after:w-full"
          >
            {title}
          </h1>
          <h2 className="text-2xl">{intro}</h2>
        </div>
      </Link>
    </span>
  )
}
