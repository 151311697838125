import { Text } from '../../containers/Language'

export default function Error() {
  return (
    <div className="min-h-screen flex flex-col rainbowBackground text-white text-4xl">
      <div className="font-extrabold m-8">Oups...</div>
      <div className="m-8">
        <Text tid="error_page_text" /> 😶‍🌫️
      </div>
    </div>
  )
}
