import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
import Lottie from 'react-lottie'
import { HelmetProvider, Helmet } from 'react-helmet-async'

import Bullet from '../../components/Bullet'

import animationData from '../../assets/home_page_animation.json'
import powerapps_icon from '../../assets/PowerApps_logo.svg'
import powerautomate_icon from '../../assets/PowerAutomate_logo.svg'
import spo_icon from '../../assets/SharePointOnline_logo.svg'

import { LanguageContext, Text, Html } from '../../containers/Language'

export default function Home() {
  const { userLanguage } = useContext(LanguageContext)

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div id="HomeWrapper">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Charles GODAERT - Your next Power Platform consultant</title>
          <meta
            name="description"
            content={
              userLanguage === 'en'
                ? 'Learn more about the Microsoft Power Platform from Charles GODAERT, the Power Apps and Power Automate expert you need.'
                : "Apprenez en plus sur la Microsoft Power Platform grâce à Charles GODAERT, l'expert Power Apps et Power Automate dont vous avez besoin."
            }
          />
          <meta name="robots" content="all" />
        </Helmet>
      </HelmetProvider>
      <section
        id="CharlesWrapper"
        className="box-border flex flex-col items-center justify-center min-h-screen p-16 flex-nowrap bg-gradient-to-tr from-[#466effb0] to-[#b63fff] lg:flex-row lg:py-32 lg:pl-48 lg:pr-24 lg:gap-16"
      >
        <div className="flex flex-col items-center gap-16 p-4 text-center basis-3/5 lg:gap-8 lg:text-left lg:items-start">
          <Fade delay={100} duration={500}>
            <h1
              className="p-0 m-0 font-medium leading-24 line-h title lg:leading-none"
              style={{ fontFamily: 'Montserrat' }}
            >
              Charles GODAERT
            </h1>
          </Fade>
          <Fade delay={200} duration={1000} top>
            <h2 className="p-0 m-0 subTitle">
              <Text tid="home_intro" />
            </h2>
            <div className="flex flex-row gap-16">
              <img
                src={powerapps_icon}
                alt="Power Apps icon"
                className="w-20 h-20"
              />
              <img
                src={powerautomate_icon}
                alt="Power Automate icon"
                className="w-20 h-20"
              />
              <img
                src={spo_icon}
                alt="SharePoint Online icon"
                className="w-20 h-20"
              />
            </div>
          </Fade>
        </div>
        <div className="flex flex-col items-center p-8 basis-2/5 lg:p-4 lg:relative lg:bottom-16">
          <span className="hidden lg:block">
            <Lottie options={animationOptions} />
          </span>
          <Link to="contact">
            <button className="rainbowButton">
              <Text tid="home_discuss" /> 🤝
            </button>
          </Link>
        </div>
      </section>
      <section
        id="TechnologyWrapper"
        className="box-border flex flex-col justify-center gap-20 px-16 min-h-screen flex-nowrap bg-gradient-to-br from-[#466effb0] to-[#b63fff] lg:px-48 lg:py-32 lg:justify-around"
      >
        <Fade duration={1500}>
          <Zoom duration={300}>
            <h1 className="title text-7xl">
              <Text tid="home_tech" /> 🚀
            </h1>
          </Zoom>
        </Fade>
        <Fade delay={200} duration={500}>
          <div className="flex flex-col gap-4 align-center">
            <div className="flex flex-row items-center">
              <img
                src={powerapps_icon}
                alt="Power Apps icon"
                className="w-20 h-20 mr-16"
              />
              <span className="subTitle">
                <Html tid="home_tech_powerapps" />
              </span>
            </div>
            <div className="flex flex-row items-center">
              <img
                src={powerautomate_icon}
                alt="Power Automate icon"
                className="w-20 h-20 mr-16"
              />
              <span className="subTitle">
                <Html tid="home_tech_powerautomate" />
              </span>
            </div>
            <div className="flex flex-row items-center">
              <img
                src={spo_icon}
                alt="SharePoint Online icon"
                className="w-20 h-20 mr-16"
              />
              <span className="subTitle">
                <Html tid="home_tech_spo" />
              </span>
            </div>
          </div>
        </Fade>
      </section>
      <section
        id="ExpertWrapper"
        className="box-border flex flex-col justify-center gap-20 min-h-screen px-16 flex-nowrap bg-gradient-to-tr from-[#466effb0] to-[#b63fff] lg:px-48 lg:py-32 lg:justify-around"
      >
        <Fade duration={1500}>
          <Zoom duration={300}>
            <h1 className="title text-7xl">
              <Text tid="home_expert" /> ✅
            </h1>
          </Zoom>
        </Fade>
        <Fade delay={200} duration={500}>
          <ul className="flex flex-col gap-10 subTitle">
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_expert_governance" />
              </span>
            </li>
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_expert_training" />
              </span>
            </li>
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_expert_conception_powerapps" />
              </span>
            </li>
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_expert_conception_powerautomate" />
              </span>
            </li>
          </ul>
        </Fade>
      </section>
      <section
        id="ExperienceWrapper"
        className="box-border flex flex-col justify-center gap-20 min-h-screen px-16 flex-nowrap bg-gradient-to-br from-[#466effb0] to-[#b63fff] lg:px-48 lg:py-32 lg:justify-around"
      >
        <Fade duration={1500}>
          <Zoom duration={300}>
            <h1 className="title text-7xl">
              <Text tid="home_experience" /> 💼
            </h1>
          </Zoom>
        </Fade>
        <Fade delay={200} duration={500}>
          <ul className="flex flex-col gap-10 subTitle">
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_experience_app" />
              </span>
            </li>
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_experience_governance" />
              </span>
            </li>
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_experience_guides" />
              </span>
            </li>
            <li className="flex items-center gap-4">
              <Bullet />
              <span>
                <Html tid="home_expreience_followup" />
              </span>
            </li>
          </ul>
        </Fade>
      </section>
    </div>
  )
}
