const prod = {
  url: {
    // API_URL: 'https://charles-test-app-1.herokuapp.com',
    API_URL: 'https://charles-blog-back.fly.dev',
  },
}
const dev = {
  url: {
    API_URL: 'http://localhost:3001',
  },
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod
