import React from 'react'
import ReactDOM from 'react-dom'
import './style/reset.css'
import './style/index.scss'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Legal from './pages/Legal'
import Blog from './pages/Blog'
import Article from './pages/Article'
import Error from './pages/Error'
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTopBtn'
import ReactRouterScrollToTop from './components/ReactRouterScrollToTop'
import { ToastContainer } from 'react-toastify'
import { LanguageProvider } from './containers/Language'

function App() {
  return (
    <React.StrictMode>
      <LanguageProvider>
        <ToastContainer />
        <Router>
          <Header />
          <ReactRouterScrollToTop />
          <ScrollToTop />
          <Routes>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/blog" element={<Blog />} />
            <Route
              path="/article/:articleTitleFormatted"
              element={<Article />}
            />
            <Route path="/*" element={<Error />} />
          </Routes>
          <Footer />
        </Router>
      </LanguageProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
