import React from 'react'

export default function CategoriesToIcons({
  categories,
  categoriesList,
  size,
}) {
  return (
    <span className="articleCategoriesIcons">
      {categories &&
        categories.map((categoryId) => {
          let category = categoriesList.find((item) => item._id === categoryId)
          if (category !== undefined) {
            return (
              <img
                key={categoryId}
                alt={category.title}
                src={category.icon}
                className={`${
                  size === 12 ? 'w-12 h-12' : 'w-24 h-24'
                } inline mr-4 my-0 rounded-xl p-1.5 object-contain bg-stone-200`}
              />
            )
          }
        })}
    </span>
  )
}
