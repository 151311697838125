import React from 'react'
import { useNavigate } from 'react-router-dom'

import './successBox.scss'

export default function SuccessBox({
  setShowPopUp,
  contentText,
  buttonText,
  redirect,
}) {
  let navigate = useNavigate()

  const handlePopUpConfirm = () => {
    setShowPopUp(false)
    navigate(redirect)
  }

  return (
    <div
      id="ConfirmBoxSuccess"
      className="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen p-0 m-0"
    >
      <div className="absolute w-full h-full bg-black opacity-40"></div>
      <div className="relative flex flex-col items-center justify-center bg-white rounded-lg">
        <div className="m-8 success-animation w-44 h-44">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <div className="m-8 text-4xl text-center text-slate-900">
          {contentText}
        </div>
        <button
          onClick={handlePopUpConfirm}
          className="m-8 hover:bg-purple-400"
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}
