import React, { useState, useEffect } from 'react'

import upArrowRoundIcon from '../assets/up-arrow-round.svg'

export default function ScrollToTopBtn() {
  const [isVisible, setIsVisible] = useState(false)

  function handleScrollToTopClick() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const onScroll = (e) => {
    let pageHeight = window.innerHeight
    let position = document.body.getBoundingClientRect().top
    if (Math.abs(position) > pageHeight) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
  }, [])

  return (
    <React.Fragment>
      {isVisible && (
        <div
          onClick={handleScrollToTopClick}
          className="fixed z-20 w-16 h-16 rounded-full cursor-pointer opacity-60 bottom-10 right-10 hover:opacity-80"
        >
          <img src={upArrowRoundIcon} alt="Scroll to top" />
        </div>
      )}
    </React.Fragment>
  )
}
